import axiosApi from '@/service/modules/axios-api'
import { SYS_MICRO_SERVICE_PREFIX } from '@/service'
// 查询角色列表
export function listRole(params) {
  return axiosApi.get(SYS_MICRO_SERVICE_PREFIX + '/role/page', params)
}

// 查询角色详细
export function getRole(params) {
  return axiosApi.get(SYS_MICRO_SERVICE_PREFIX + '/role/getById', params)
}
// 新增角色
export function addRole(data) {
  return axiosApi.post(SYS_MICRO_SERVICE_PREFIX + '/role/add', data)
}

// 修改角色
export function updateRole(data) {
  return axiosApi.post(SYS_MICRO_SERVICE_PREFIX + '/role/edit', data)
}

// 删除角色
export function delRole(data) {
  return axiosApi.post(SYS_MICRO_SERVICE_PREFIX + '/role/remove', data)
}

// 修改角色状态
export function shiftRoleStatus(data) {
  return axiosApi.post(SYS_MICRO_SERVICE_PREFIX + '/role/edit/status', data)
}
